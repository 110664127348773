import React, { useState } from "react";

import { MdAdd } from "react-icons/md";
import { useHistory } from "react-router-dom";

import {
  ManagerRequirement,
  useManagerRequirements,
} from "../../../hook/queries/useManagerRequirements";

import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { PanelLayout } from "../../../components/panel/PanelLayout";
import { Pagination } from "../../../components/panel/table/Pagination";
import {
  FieldProps,
  TableContent,
} from "../../../components/panel/table/TableContent";
import { TableHeader } from "../../../components/panel/table/TableHeader";

const columns: FieldProps<ManagerRequirement>[] = [
  {
    name: "id",
    field: "Cód",
    viewList: true,
  },
  {
    name: "description",
    field: "Descrição",
    viewList: true,
  },
  {
    name: "value",
    field: "Abreviação",
    viewList: true,
  },
];

const Panel: React.FC = () => {
  const history = useHistory();

  const [page, setPage] = useState<number>(1);
  const { data, isLoading, isFetching } = useManagerRequirements(page);

  return (
    <PanelLayout>
      <PanelAndDetailAndCreateHeader title="Lista de Requisitos do gerente" />

      <TableHeader
        title="Requisitos do Gerente"
        isFetching={isFetching && !isLoading}
      >
        <button
          onClick={() => {
            history.push("/criar/gerente-requisito");
          }}
          type="button"
        >
          <MdAdd size={22} /> <span> CRIAR </span>
        </button>
      </TableHeader>

      <TableContent<any>
        data={data?.contents}
        columns={columns.filter((f) => f.viewList)}
        isLoading={isLoading}
        onClickRow={(e) => history.push(`/gerente-requisito/${e.id}`)}
      />
      <Pagination
        totalCountOfRegisters={data?.totalCount ?? 0}
        currentPage={page}
        onPageChange={setPage}
      />
    </PanelLayout>
  );
};

export default Panel;
