import React, { CSSProperties } from "react";

import { Container } from "./styles";

interface Props {
  styles?: CSSProperties;
}

export const PanelLayout: React.FC<Props> = ({ children, ...rest }) => {
  return <Container {...rest}>{children}</Container>;
};
