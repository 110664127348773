import React from "react";

import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { Formik } from "formik";
import { FaArrowLeft } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import * as Yup from "yup";

import api from "../../../service/api";
import { ButtunSubmit, Container, ContainerForm, Error, Form } from "../styles";

const CreateUser: React.FC = () => {
  const { addToast } = useToasts();
  const history = useHistory();

  async function handleSubmit(create: any) {
    try {
      await api.post("/response_item", {
        description: create.description,
        value: create.value,
        obs: Boolean(Number(create.obs)),
        valid: Boolean(Number(create.valid)),
      });

      addToast("Resposta criada com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/respostas");
    } catch (err) {
      const error = err as any;

      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (
        String(error.response.data.message).toLocaleLowerCase() ===
        "value exist"
      ) {
        return addToast("Este valor já existe em nossa base de dados", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <Container>
      <header>
        <button
          type="button"
          onClick={() => {
            history.goBack();
          }}
          className="arrowBack"
        >
          <FaArrowLeft size={18} />
        </button>

        <h2>Criar Resposta</h2>
      </header>

      <ContainerForm>
        <Formik
          initialValues={{
            description: undefined,
            obs: 1,
            valid: 1,
            value: undefined,
          }}
          validationSchema={Yup.object({
            description: Yup.string().required("Descrição é obrigatório"),
            value: Yup.string().required("Valor é obrigatório"),
          })}
          onSubmit={(data) => handleSubmit(data)}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <div className="field">
                <label htmlFor="description">Descrição</label>
                <input {...formik.getFieldProps("description")} />
                {formik.touched.description && formik.errors.description ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.description} </span>
                  </Error>
                ) : null}
              </div>

              <div className="field-group">
                <div className="field">
                  <label htmlFor="obs">Observção</label>
                  <select {...formik.getFieldProps("obs")}>
                    <option value={1}>Sim</option>
                    <option value={0}>Não</option>
                  </select>
                </div>
                <div className="field">
                  <label htmlFor="valid">Valido</label>
                  <select {...formik.getFieldProps("valid")}>
                    <option value={1}>Sim</option>
                    <option value={0}>Não</option>
                  </select>
                </div>
              </div>
              <div className="field">
                <label htmlFor="value">Valor</label>
                <input {...formik.getFieldProps("value")} />
                {formik.touched.value && formik.errors.value ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.value} </span>
                  </Error>
                ) : null}
              </div>
              <ButtunSubmit type="submit"> Criar </ButtunSubmit>
            </Form>
          )}
        </Formik>
      </ContainerForm>
    </Container>
  );
};

export default CreateUser;
