import React, { useEffect, useRef, useState } from "react";

import { Link, useHistory } from "react-router-dom";
import useOutsideAlerter from "../../../hook/outsideAlerter";

import moment from "moment";

import { FaArrowLeft } from "react-icons/fa";
import { IoAnalyticsOutline } from "react-icons/io5";
import { MdArrowDownward } from "react-icons/md";
import { RiFileExcel2Line } from "react-icons/ri";
import { IDepartmental_evaluation_show } from "../../../@types/types";
import Loading from "../../../components/Loading";
import api from "../../../service/api";
import {
  Container,
  ContainerList,
  FooterContainer,
  InfoDevolution,
  InfoStatus,
  ReportDownload,
  ScreenLoading,
  TableContainer,
} from "../styles";

interface Props {
  location: {
    state: {};
  };

  match: {
    params: {
      id: number;
    };
  };
}

const Detail: React.FC<Props> = ({ match }) => {
  const history = useHistory();

  const outSideRefReport = useRef(null);

  const [contentPage, setContentPage] =
    useState<IDepartmental_evaluation_show>();
  const [visibleReport, setVisibleReport] = useState(false);

  const [loading, setLoading] = useState({
    page: true,
    report: false,
  });

  useEffect(() => {
    if (match.params.id) {
      (async () => {
        const dataDB = await api.get<any>(
          `/departamental_evaluation/${match.params.id}`
        );

        setContentPage(dataDB.data);

        setLoading({ ...loading, page: false });
      })();
    }
    // eslint-disable-next-line
  }, [match.params.id]);

  useOutsideAlerter(outSideRefReport, () => {
    if (visibleReport) setVisibleReport(false);
  });

  async function hadleReportCreate(id: number) {
    setLoading({ ...loading, report: true });
    try {
      if (id === 2) {
        const response = await api.post<any>(
          `/departamental_evaluation/export/percentage/${contentPage?.id}`,
          {
            typeReport: id,
          }
        );
        window.open(response.data.url);
      }
      if (id === 1) {
        const response = await api.post<any>(
          `/departamental_evaluation/export/obs/${contentPage?.id}`,
          {
            typeReport: id,
          }
        );
        window.open(response.data.url);
      }
      if (id === 4) {
        const response = await api.post<any>(
          `/departamental_evaluation/export/obsSectorManager/${contentPage?.id}`,
          {
            typeReport: id,
          }
        );
        window.open(response.data.url);
      }
      if (id === 3) {
        const response = await api.post<any>(
          `/departamental_evaluation/export/client/${contentPage?.id}`,
          {
            typeReport: id,
          }
        );
        window.open(response.data.url);
      }

      if (id === 5) {
        const response = await api.post<any>(
          `/departamental_evaluation/export/obsManager/${contentPage?.id}`,
          {
            typeReport: id,
          }
        );
        window.open(response.data.url);
      }
      if (id === 6) {
        const response = await api.post<any>(
          `/departamental_evaluation/export/generalInformation/${contentPage?.id}`,
          {
            typeReport: id,
          }
        );
        window.open(response.data.url);
      }
      if (id === 7) {
        const response = await api.post<any>(
          `/departamental_evaluation/export/benefit/${contentPage?.id}`,
          {
            typeReport: id,
          }
        );
        window.open(response.data.url);
      }

      setLoading({ ...loading, report: false });
    } catch (error) {
      setLoading({ ...loading, report: false });
    }
  }

  return (
    <Container>
      {!loading.page ? (
        <>
          <header>
            <button
              type="button"
              onClick={() => {
                history.goBack();
              }}
              className="arrowBack"
            >
              <FaArrowLeft size={18} />
            </button>
            <h2>Avaliação Departamental</h2>

            <div className="reportDownload">
              <ReportDownload
                ref={outSideRefReport}
                onClick={() => setVisibleReport(!visibleReport)}
              >
                {loading.report ? (
                  <Loading
                    borderSize={5}
                    size={20}
                    colorLoading="#259457"
                    borderColor="rgba(32, 114, 69, 0.5)"
                  />
                ) : (
                  <div className="icon-execel">
                    <RiFileExcel2Line color="#207245" size={24} />
                    <div
                      className={
                        visibleReport ? "row-excel-active" : "row-excel"
                      }
                    >
                      <MdArrowDownward color="#207245" size={16} />
                    </div>
                  </div>
                )}

                {visibleReport ? (
                  <ul className="optionReport">
                    <li onClick={() => hadleReportCreate(2)}>
                      <div className="icon-execel">
                        <RiFileExcel2Line color="#207245" size={18} />
                      </div>
                      <span>Relatório para analise</span>
                    </li>
                    <li onClick={() => hadleReportCreate(1)}>
                      <div className="icon-execel">
                        <RiFileExcel2Line color="#207245" size={18} />
                      </div>
                      <span>Relatório Observações</span>
                    </li>
                    <li onClick={() => hadleReportCreate(4)}>
                      <div className="icon-execel">
                        <RiFileExcel2Line color="#207245" size={18} />
                      </div>
                      <span>Relatório Observações do gestor da área</span>
                    </li>
                    <li onClick={() => hadleReportCreate(3)}>
                      <div className="icon-execel">
                        <RiFileExcel2Line color="#207245" size={18} />
                      </div>
                      <span>Relatório Clientes</span>
                    </li>
                    <li onClick={() => hadleReportCreate(5)}>
                      <div className="icon-execel">
                        <RiFileExcel2Line color="#207245" size={18} />
                      </div>
                      <span>Relatório Observações do gestor responsável</span>
                    </li>
                    <li onClick={() => hadleReportCreate(6)}>
                      <div className="icon-execel">
                        <RiFileExcel2Line color="#207245" size={18} />
                      </div>
                      <span>Relatório Questões gerais</span>
                    </li>
                    <li onClick={() => hadleReportCreate(7)}>
                      <div className="icon-execel">
                        <RiFileExcel2Line color="#207245" size={18} />
                      </div>
                      <span>Relatório Ordem importância de benefícios</span>
                    </li>
                  </ul>
                ) : null}
              </ReportDownload>
            </div>
          </header>

          <InfoStatus>
            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>TÍTULO</h5>
                </div>
                <p>
                  {contentPage && contentPage.title !== undefined
                    ? `${contentPage.title}`
                    : `-`}
                </p>
              </div>
            </div>

            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>VOTOS</h5>
                </div>
                <p>
                  {contentPage && contentPage.title !== undefined
                    ? `${contentPage.qtdClientFinished}`
                    : `-`}
                </p>
              </div>
            </div>

            {contentPage && !contentPage.finished && (
              <div className="containerStatus">
                <div className="box">
                  <div>
                    <h5>CRIADO EM</h5>
                  </div>
                  <p>{moment(contentPage.created_at).format("DD/MM/YYYY")}</p>
                </div>
              </div>
            )}

            {contentPage && contentPage.finished && (
              <>
                <div className="containerStatus">
                  <div className="box">
                    <div>
                      <h5>CRIADO EM</h5>
                    </div>
                    <p>{moment(contentPage.created_at).format("DD/MM/YYYY")}</p>
                  </div>
                </div>
                <div className="containerStatus">
                  <div className="box">
                    <div>
                      <h5>FINALIZADO EM</h5>
                    </div>
                    <p>
                      {moment(contentPage.finished_at).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </div>
              </>
            )}
          </InfoStatus>

          <InfoDevolution>
            <div className="headerDevoltion">
              <h2>Informações</h2>

              {contentPage && (
                <Link to={`/analise/avaliacao/${contentPage.id}`}>
                  <IoAnalyticsOutline size={24} />
                  <span>Análise</span>
                </Link>
              )}
            </div>
            <div className="bodyDevoltion">
              {contentPage &&
                contentPage.departmentalEvaluationData &&
                contentPage.departmentalEvaluationData.map(
                  (sector, sectorIndex) => (
                    <div className="conatinerInfo" key={sectorIndex}>
                      <h3>{sector.setor}</h3>
                      <div className="line"></div>

                      {sector.requirement.map(
                        (requirement, requirementIndex) => (
                          <div key={requirementIndex}>
                            <h4>{requirement.descripition}</h4>

                            {requirement.responseItem.map(
                              (responseItem, responseItemIndex) => (
                                <p key={responseItemIndex}>
                                  {responseItem.descripition} :{" "}
                                  {responseItem.qtd}
                                </p>
                              )
                            )}
                          </div>
                        )
                      )}
                    </div>
                  )
                )}
            </div>
          </InfoDevolution>

          <ContainerList>
            <div className="headerTable">
              <h3>Clientes</h3>
            </div>

            <TableContainer>
              <table>
                <thead>
                  <tr>
                    <th>FORMULÁRIO</th>
                    <th>CÓDIGO</th>
                  </tr>
                </thead>

                <tbody>
                  {contentPage && contentPage.client ? (
                    contentPage.client.map((client, index) => (
                      <tr
                        style={{
                          cursor: "auto",
                        }}
                        key={Number(index)}
                      >
                        <td> {contentPage.id} </td>
                        <td> {client.cod} </td>
                      </tr>
                    ))
                  ) : (
                    <tr></tr>
                  )}
                </tbody>
              </table>
            </TableContainer>
            <FooterContainer>
              <span>
                Mostrando {contentPage?.client.length} de{" "}
                {contentPage?.client.length} resultados
              </span>
              {/* 
                <div>
                  <button
                    type="button"
                    className={
                      paginationShippinganalysis.page < 1 ? "buttonNull" : ""
                    }
                    onClick={
                      paginationShippinganalysis.page < 1
                        ? () => {}
                        : hadlePreviousPageShippingAnalysis
                    }
                  >
                    ANTERIOR
                  </button>
                  <p style={{ marginRight: 8 }}>
                    {paginationShippinganalysis.page + 1}
                  </p>
                  <button
                    className={
                      paginationShippinganalysis.page >=
                      paginationShippinganalysis.pages
                        ? "buttonNull"
                        : ""
                    }
                    type="button"
                    onClick={
                      paginationShippinganalysis.page >=
                      paginationShippinganalysis.pages
                        ? () => {}
                        : hadleNextPageShippingAnalysis
                    }
                  >
                    PROXIMO
                  </button>
                </div>
               */}
            </FooterContainer>
          </ContainerList>
        </>
      ) : (
        <ScreenLoading>
          <Loading
            colorLoading="#333"
            size={40}
            borderColor={"#888"}
            borderSize={5}
          />
        </ScreenLoading>
      )}
    </Container>
  );
};

export default Detail;
