import styled from "styled-components";

export const Container = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  .headerTable {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    height: 45px;
    background-color: #ccc;

    .containerTitle {
      display: flex;
      align-items: center;

      h3 {
        font-size: 1rem;
        color: #111;
        font-weight: 500;
        margin-right: 8px;
      }
    }

    button {
      padding: 8px 10px;
      height: 28px;
      background-color: #4fb0a5;
      border-radius: 5px;

      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all 0.3s;

      &:hover {
        opacity: 0.6;
      }

      svg {
        color: #fff;
      }

      span {
        margin-left: 5px;
        font-size: 12px;
        color: #fff;
        font-weight: 500;
      }

      @media (max-width: 650px) {
        svg {
          margin-right: 0px;
        }
        span {
          display: none;
        }
      }
    }

    .containerActions {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }
  }
`;
