import React, { useEffect, useState } from "react";

import { FaArrowLeft } from "react-icons/fa";
import { VscTriangleRight } from "react-icons/vsc";
import { useHistory } from "react-router-dom";
import api from "../../../service/api";

import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";

// import { scaleOrdinal } from "d3-scale";
// import { /* schemeSet3 */ schemeTableau10 } from "d3-scale-chromatic";

import { dataUndefined } from "../../../utils/dataProcessing";

import {
  BoxGraphics,
  Container,
  ContainerGraphics,
  InfoStatus,
  ScreenLoading,
} from "../styles";

import Loading from "../../../components/Loading";

// const colors = scaleOrdinal(schemeSet3).range();
// const COLORS = scaleOrdinal(schemeTableau10).range();
const COLORS = [
  "rgb(225, 87, 89)",
  "rgb(118, 183, 178)",
  "rgb(242, 142, 44)",
  "rgb(78, 121, 167)",
];

interface IDepartamentalEvaluationAnalyticSector {
  id: number;
  name: string;
  qtd: number;
  obs: string[];
  obsManager: string[];

  requirement: {
    id: number;
    description: string;
    qtd: number;

    analytic: {
      id: number;
      name: string;
      length: number;
    }[];
  }[];
}

interface IDepartamentalEvaluationAnalyticManager {
  id: number;
  name: string;
  qtd: number;
  obs: string[];

  requirement: {
    id: number;
    description: string;
    qtd: number;

    analytic: {
      id: number;
      name: string;
      length: number;
    }[];
  }[];
}

interface IResponseRoute {
  title: string;
  qtd_client: number;
  qtd_client_finished: number;
  analyticSector: IDepartamentalEvaluationAnalyticSector[];
  analyticManager: IDepartamentalEvaluationAnalyticManager[];
}

interface Props {
  location: {
    state: {};
  };

  match: {
    params: {
      id: number;
    };
  };
}

const PieTooltip = (props: any) => {
  if (props.active) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: 8,
          border: "0.5px solid #333",
          opacity: 0.9,
        }}
      >
        <p className="label">{`${props.payload[0].payload.name}`}</p>
        <p className="label">{`Qtd : ${props.payload[0].payload.length}`}</p>
      </div>
    );
  }

  return null;
};

// const BarTooltip = (props: any) => {
//   if (props.active) {
//     return (
//       <div
//         className="custom-tooltip"
//         style={{
//           backgroundColor: "#fff",
//           padding: 8,
//           border: "0.5px solid #333",
//           opacity: 0.9,
//         }}
//       >
//         <p className="label">{`${props.payload[0].payload.name}`}</p>
//         <p className="label">{`Qtd : ${props.payload[0].payload.length}`}</p>
//         <p className="label">{`Valor : ${Number(
//           props.payload[0].payload.value
//         ).toLocaleString("pt-br", {
//           style: "currency",
//           currency: "BRL",
//         })}`}</p>
//       </div>
//     );
//   }

//   return null;
// };

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = (
  //@ts-ignore
  { cx, cy, midAngle, innerRadius, outerRadius, percent, index }
) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {percent * 100 < 1 ? "" : `${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Analytic: React.FC<Props> = ({ match }) => {
  const history = useHistory();

  const [screenLoading, setScreenLoading] = useState<boolean>(true);

  const [analytic, setAnalytic] = useState<IResponseRoute>(
    {} as IResponseRoute
  );

  useEffect(() => {
    (async () => {
      setScreenLoading(true);
      const analisycDevolutionResponse = await api.get<IResponseRoute>(
        `/departamental_evaluation/analytic/${match.params.id}`
      );

      setAnalytic(analisycDevolutionResponse.data);
      setScreenLoading(false);
    })();
  }, [match]);

  // function setChangeCheckbox(e: React.ChangeEvent<HTMLInputElement>) {
  //   console.log(e.target.checked);
  //   console.log(e.target.name);
  //   console.log(e.target.id);

  //   //@ts-ignore
  //   let arrFilter = filters[e.target.name];

  //   if (filters.status) {
  //     arrFilter?.push(e.target.id);
  //   } else {
  //   }

  //   setFilters({ ...filters, status: arrFilter });
  // }

  // function getChangeCheckbox(name: string, titleArr: string): boolean {
  //   let response = true;
  //   console.log(name);
  //   console.log(titleArr);
  //   // //@ts-ignore
  //   // let arrFilter = filters[titleArr];

  //   // arrFilter.map((element: string) => {
  //   //   if (element === name) {
  //   //     response = true;
  //   //   }
  //   // });

  //   return response;
  // }

  return (
    <Container>
      <header>
        <button
          type="button"
          onClick={() => {
            history.goBack();
          }}
          className="arrowBack"
        >
          <FaArrowLeft size={18} />
        </button>
        <h2>Análise Avaliação Departamental</h2>
      </header>
      {!screenLoading ? (
        <>
          <InfoStatus>
            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>TITULO</h5>
                </div>
                <p>{dataUndefined(analytic.title)}</p>
              </div>
            </div>

            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>CLIENTES</h5>
                </div>
                <p>{dataUndefined(analytic.qtd_client)}</p>
              </div>
            </div>
            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>RESPOSTAS</h5>
                </div>
                <p>{dataUndefined(analytic.qtd_client_finished)}</p>
              </div>
            </div>
          </InfoStatus>

          <h2>Setores</h2>
          {analytic.analyticSector &&
            analytic.analyticSector.map((sector, index) => (
              <ContainerGraphics key={index}>
                <nav className="headerGraphics">
                  <h5>{sector.name}</h5>
                </nav>
                <div className="bodyGraphics">
                  {sector.requirement.map((requirement, indexRequirement) => (
                    <BoxGraphics key={indexRequirement}>
                      <span>{requirement.description}</span>
                      <span>Qtd respostas validas: {requirement.qtd}</span>
                      <PieChart width={300} height={220}>
                        <Pie
                          data={requirement.analytic}
                          cx="50%"
                          cy="50%"
                          label={renderCustomizedLabel}
                          labelLine={false}
                          dataKey="length"
                          innerRadius={30}
                          outerRadius={80}
                        >
                          {requirement.analytic.map((_, indexRequirement) => (
                            <Cell
                              key={`cell-${indexRequirement}`}
                              fill={COLORS[indexRequirement % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip content={<PieTooltip />} />
                        <Legend />
                      </PieChart>
                    </BoxGraphics>
                  ))}
                </div>

                <div className="containerOBS">
                  <span>Observações</span>

                  <div>
                    {sector.obs.length <= 0 && (
                      <span style={{ fontSize: 14 }}>
                        Não há dados a serem mostrados
                      </span>
                    )}

                    {sector.obs.map((obs) => (
                      <p>
                        <VscTriangleRight size={13} />
                        {obs};
                      </p>
                    ))}
                  </div>
                </div>

                <div className="containerOBS" style={{ marginTop: "1rem" }}>
                  <span>Observações do gestor da área</span>

                  <div>
                    {sector.obsManager.length <= 0 && (
                      <span style={{ fontSize: 14 }}>
                        Não há dados a serem mostrados
                      </span>
                    )}

                    {sector.obsManager.map((obs) => (
                      <p>
                        <VscTriangleRight size={13} />
                        {obs};
                      </p>
                    ))}
                  </div>
                </div>
              </ContainerGraphics>
            ))}

          <h2>Gerentes diretos</h2>
          {analytic.analyticManager &&
            analytic.analyticManager.map((sector, index) => (
              <ContainerGraphics key={index}>
                <nav className="headerGraphics">
                  <h5>{sector.name}</h5>
                </nav>
                <div className="bodyGraphics">
                  {sector.requirement.map((requirement, indexRequirement) => (
                    <BoxGraphics key={indexRequirement}>
                      <span>{requirement.description}</span>
                      <span>Qtd: {requirement.qtd}</span>
                      <PieChart width={200} height={250}>
                        <Pie
                          data={requirement.analytic}
                          cx="50%"
                          cy="50%"
                          label={renderCustomizedLabel}
                          labelLine={false}
                          dataKey="length"
                          innerRadius={20}
                          outerRadius={40}
                        >
                          {requirement.analytic.map((_, indexRequirement) => (
                            <Cell
                              key={`cell-${indexRequirement}`}
                              fill={COLORS[indexRequirement % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip content={<PieTooltip />} />
                        <Legend />
                      </PieChart>
                    </BoxGraphics>
                  ))}
                </div>

                <div className="containerOBS">
                  <span>Observações</span>

                  <div>
                    {sector.obs.length <= 0 && (
                      <span style={{ fontSize: 14 }}>
                        Não há dados a serem mostrados
                      </span>
                    )}

                    {sector.obs.map((obs) => (
                      <p>
                        <VscTriangleRight size={13} />
                        {obs};
                      </p>
                    ))}
                  </div>
                </div>
              </ContainerGraphics>
            ))}
        </>
      ) : (
        <ScreenLoading>
          <Loading
            colorLoading="#333"
            size={40}
            borderColor={"#888"}
            borderSize={5}
          />
        </ScreenLoading>
      )}
    </Container>
  );
};

export default Analytic;
