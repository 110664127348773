import { useQuery, UseQueryResult } from "react-query";

import { ResponsePanelApiDefault } from "../../@types/panelTypes";
import api from "../../service/api";

export type ManagerRequirement = {
  id: number;
  name: string;
};

type GetResponse = {
  contents: ManagerRequirement[];
  totalCount: number;
};

interface ApiResponse extends ResponsePanelApiDefault {
  contents: ManagerRequirement[];
}

export async function getManagerRequirements(
  page: number,
  registersPerPag?: number
): Promise<GetResponse> {
  const { data } = await api.get<ApiResponse>("/manager-requirements", {
    params: { pageRequest: page - 1, limitRequest: registersPerPag },
  });

  return {
    totalCount: data.total,
    contents: data.contents,
  };
}

export function useManagerRequirements(
  page: number,
  registersPerPag?: number
): UseQueryResult<GetResponse> {
  return useQuery(
    ["manager-requirements", page, registersPerPag],
    () => getManagerRequirements(page, registersPerPag),
    {
      staleTime: 1000 * 60 * 10, // 10 Minutos
    }
  );
}

export async function getManagerRequirement(
  id?: number
): Promise<ManagerRequirement | undefined> {
  if (!id && id !== 0) return undefined;

  const { data } = await api.get<ManagerRequirement>(
    `/manager-requirements/${id}`
  );

  return data;
}

export function useManagerRequirement(
  id?: number
): UseQueryResult<ManagerRequirement> | undefined {
  return useQuery(
    ["manager-requirement", id],
    () => getManagerRequirement(id),
    {
      staleTime: 1000 * 60 * 10, // 10 Minutos
    }
  );
}
