import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";

import * as authUser from "./service/authUser";

import { Grid } from "./styles/global";

import NavbarLeft from "./components/NavbarLeft";
import NavbarTop from "./components/NavbarTop";

import CreateSector from "./pages/create/CreateSector";
import Sector from "./pages/detail/Sector";
import PanelSectors from "./pages/panel/PanelSectors";

import ManagerCreateAndDetail from "./pages/createAndDetail/Manager";
import PanelManager from "./pages/panel/PanelManager";

import BenefitCreateAndDetail from "./pages/createAndDetail/Benefit";
import PanelBenefit from "./pages/panel/PanelBenefit";

import ManagerRequirementCreateAndDetail from "./pages/createAndDetail/ManagerRequirement";
import PanelManagerRequirement from "./pages/panel/PanelManagerRequirement";

import ManagerRespondeCreateAndDetail from "./pages/createAndDetail/ManagerResponde";
import PanelManagerResponde from "./pages/panel/PanelManagerResponde";

import CreateRequirement from "./pages/create/CreateRequirement";
import Requirement from "./pages/detail/Requirement";
import PanelRequirements from "./pages/panel/PanelRequirements";

import CreateResponseItem from "./pages/create/CreateResponseItem";
import ResponseItem from "./pages/detail/ResponseItem";
import PanelResponseItems from "./pages/panel/PanelResponseItems";

import AnalyticDepartamentalEvaluation from "./pages/analytic/DepartamentalEvaluation";
import CreateDepartamentalEvaluation from "./pages/create/CreateDepartamentalEvaluation";
import DepartamentalEvaluation from "./pages/detail/DepartamentalEvaluation";
import PanelDepartamentalEvaluation from "./pages/panel/PanelDepartamentalEvaluation";

import Login from "./pages/Login";

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  ...rest
}: any) => {
  const routeComponent = (props: any) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          authUser.isAuthenticated() ? (
            <Grid>
              <NavbarTop />
              <NavbarLeft />
              <Component {...props} />
            </Grid>
          ) : (
            <Redirect to="/" />
          )
        }
      />
    );
  };
  return <Route {...rest} render={routeComponent} />;
};

const PublicRoute = ({
  component: Component,
  isAuthenticated,
  ...rest
}: any) => {
  const routeComponent = (props: any) => (
    <Route
      {...rest}
      render={(props) =>
        authUser.isAuthenticated() ? (
          <Redirect to="/inicio" />
        ) : (
          <Component seller {...props} />
        )
      }
    />
  );
  return <Route {...rest} render={routeComponent} />;
};

function Routes() {
  return (
    <BrowserRouter>
      <ToastProvider>
        <Switch>
          <PrivateRoute component={() => null} exact path="/inicio" />

          {/* Avaliação departamental */}
          <PrivateRoute
            component={PanelDepartamentalEvaluation}
            exact
            path="/avaliacoes"
          />
          <PrivateRoute
            component={DepartamentalEvaluation}
            exact
            path="/avaliacao/:id"
          />
          <PrivateRoute
            component={CreateDepartamentalEvaluation}
            exact
            path="/criar/avaliacao"
          />
          <PrivateRoute
            component={AnalyticDepartamentalEvaluation}
            exact
            path="/analise/avaliacao/:id"
          />

          {/* Setor */}
          <PrivateRoute component={PanelSectors} exact path="/setores" />
          <PrivateRoute component={Sector} exact path="/setor/:id" />
          <PrivateRoute component={CreateSector} exact path="/criar/setor" />

          {/* Gerente */}
          <PrivateRoute component={PanelManager} exact path="/gerentes" />
          <PrivateRoute
            component={ManagerCreateAndDetail}
            exact
            path="/gerente/:id"
          />
          <PrivateRoute
            component={ManagerCreateAndDetail}
            exact
            path="/criar/gerente"
          />

          {/* Gerente Requisito */}
          <PrivateRoute
            component={PanelManagerRequirement}
            exact
            path="/gerente-requisitos"
          />
          <PrivateRoute
            component={ManagerRequirementCreateAndDetail}
            exact
            path="/gerente-requisito/:id"
          />
          <PrivateRoute
            component={ManagerRequirementCreateAndDetail}
            exact
            path="/criar/gerente-requisito"
          />
          {/* Gerente Repostas */}
          <PrivateRoute
            component={PanelManagerResponde}
            exact
            path="/gerente-respostas"
          />
          <PrivateRoute
            component={ManagerRespondeCreateAndDetail}
            exact
            path="/gerente-resposta/:id"
          />
          <PrivateRoute
            component={ManagerRespondeCreateAndDetail}
            exact
            path="/criar/gerente-resposta"
          />
          {/* Benefícios */}
          <PrivateRoute component={PanelBenefit} exact path="/beneficios" />
          <PrivateRoute
            component={BenefitCreateAndDetail}
            exact
            path="/beneficio/:id"
          />
          <PrivateRoute
            component={BenefitCreateAndDetail}
            exact
            path="/criar/beneficio"
          />

          {/* Requisito */}
          <PrivateRoute
            component={PanelRequirements}
            exact
            path="/requisitos"
          />
          <PrivateRoute component={Requirement} exact path="/requisito/:id" />
          <PrivateRoute
            component={CreateRequirement}
            exact
            path="/criar/requisito"
          />

          {/* Resposta por item */}
          <PrivateRoute
            component={PanelResponseItems}
            exact
            path="/respostas"
          />
          <PrivateRoute component={ResponseItem} exact path="/resposta/:id" />
          <PrivateRoute
            component={CreateResponseItem}
            exact
            path="/criar/resposta"
          />

          {/* Auth */}
          <PublicRoute component={Login} exact path="/" />
          <Route
            component={() => (
              <div
                style={{
                  height: "100vh",
                  width: "100vw",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <p style={{ fontSize: 64 }}>Erro 404</p>
              </div>
            )}
            exact
            path="*"
          />
        </Switch>
      </ToastProvider>
    </BrowserRouter>
  );
}

export default Routes;
