import { useQuery, UseQueryResult } from "react-query";

import { ResponsePanelApiDefault } from "../../@types/panelTypes";
import api from "../../service/api";

export type ManagerResponse = {
  id: number;
  description: string;
  value: number;
  obs: boolean;
  valid: boolean;
};

type GetResponse = {
  contents: ManagerResponse[];
  totalCount: number;
};

interface ApiResponse extends ResponsePanelApiDefault {
  contents: ManagerResponse[];
}

export async function getManagerResponses(
  page: number,
  registersPerPag?: number
): Promise<GetResponse> {
  const { data } = await api.get<ApiResponse>("/manager-responses", {
    params: { pageRequest: page - 1, limitRequest: registersPerPag },
  });

  return {
    totalCount: data.total,
    contents: data.contents,
  };
}

export function useManagerResponses(
  page: number,
  registersPerPag?: number
): UseQueryResult<GetResponse> {
  return useQuery(
    ["manager-responses", page, registersPerPag],
    () => getManagerResponses(page, registersPerPag),
    {
      staleTime: 1000 * 60 * 10, // 10 Minutos
    }
  );
}

export async function getManagerResponse(
  id?: number
): Promise<ManagerResponse | undefined> {
  if (!id && id !== 0) return undefined;

  const { data } = await api.get<ManagerResponse>(`/manager-responses/${id}`);

  return data;
}

export function useManagerResponse(
  id?: number
): UseQueryResult<ManagerResponse> | undefined {
  return useQuery(["manager-response", id], () => getManagerResponse(id), {
    staleTime: 1000 * 60 * 10, // 10 Minutos
  });
}
