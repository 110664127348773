import { useQuery, UseQueryResult } from "react-query";

import { ResponsePanelApiDefault } from "../../@types/panelTypes";
import api from "../../service/api";

export type Benefit = {
  id: number;
  name: string;
};

type GetResponse = {
  contents: Benefit[];
  totalCount: number;
};

interface ApiResponse extends ResponsePanelApiDefault {
  contents: Benefit[];
}

export async function getBenefits(
  page: number,
  registersPerPag?: number
): Promise<GetResponse> {
  const { data } = await api.get<ApiResponse>("/benefits", {
    params: { pageRequest: page - 1, limitRequest: registersPerPag },
  });

  return {
    totalCount: data.total,
    contents: data.contents,
  };
}

export function useBenefits(
  page: number,
  registersPerPag?: number
): UseQueryResult<GetResponse> {
  return useQuery(
    ["benefits", page, registersPerPag],
    () => getBenefits(page, registersPerPag),
    {
      staleTime: 1000 * 60 * 10, // 10 Minutos
    }
  );
}

export async function getBenefit(id?: number): Promise<Benefit | undefined> {
  if (!id && id !== 0) return undefined;

  const { data } = await api.get<Benefit>(`/benefits/${id}`);

  return data;
}

export function useBenefit(id?: number): UseQueryResult<Benefit> | undefined {
  return useQuery(["benefit", id], () => getBenefit(id), {
    staleTime: 1000 * 60 * 10, // 10 Minutos
  });
}
