import React, { useState } from "react";

import { MdAdd } from "react-icons/md";
import { useHistory } from "react-router-dom";

import { Benefit, useBenefits } from "../../../hook/queries/useBenefits";

import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { PanelLayout } from "../../../components/panel/PanelLayout";
import { Pagination } from "../../../components/panel/table/Pagination";
import {
  FieldProps,
  TableContent,
} from "../../../components/panel/table/TableContent";
import { TableHeader } from "../../../components/panel/table/TableHeader";

const columns: FieldProps<Benefit>[] = [
  {
    name: "id",
    field: "Cód",
    viewList: true,
  },
  {
    name: "name",
    field: "Nome",
    viewList: true,
  },
];

const Panel: React.FC = () => {
  const history = useHistory();

  const [page, setPage] = useState<number>(1);
  const { data, isLoading, isFetching } = useBenefits(page);

  return (
    <PanelLayout>
      <PanelAndDetailAndCreateHeader title="Lista de benefícios" />

      <TableHeader title="Benefício" isFetching={isFetching && !isLoading}>
        <button
          onClick={() => {
            history.push("/criar/beneficio");
          }}
          type="button"
        >
          <MdAdd size={22} /> <span> CRIAR </span>
        </button>
      </TableHeader>

      <TableContent<any>
        data={data?.contents}
        columns={columns.filter((f) => f.viewList)}
        isLoading={isLoading}
        onClickRow={(e) => history.push(`/beneficio/${e.id}`)}
      />
      <Pagination
        totalCountOfRegisters={data?.totalCount ?? 0}
        currentPage={page}
        onPageChange={setPage}
      />
    </PanelLayout>
  );
};

export default Panel;
