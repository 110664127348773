import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import * as Yup from "yup";

import { useToasts } from "react-toast-notifications";
import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { ButtonSubmit } from "../../../components/Form/ButtonSubmit";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { LayoutForm } from "../../../components/Form/LayoutForm";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import {
  ManagerRequirement,
  useManagerRequirement,
} from "../../../hook/queries/useManagerRequirements";
import api from "../../../service/api";
import { queryClient } from "../../../service/queryClient";
import { setFormikValues } from "../../../utils/setFormikValues";

export interface MatchParams {
  id?: string;
}

type CreateProps = Omit<ManagerRequirement, "id">;

const CreateAndDetail: React.FC = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const match = useRouteMatch<MatchParams>();
  const isUpdating = match?.params?.id ? true : false;
  const getManagerRequirement = useManagerRequirement(Number(match.params.id));

  const formik = useFormik({
    initialValues: {
      id: "",
      description: "",
      value: "",
    },
    validationSchema: Yup.object({
      description: Yup.string().required("É obrigatório"),
      value: Yup.string().required("É obrigatório"),
    }),
    onSubmit: (a) => {
      //@ts-ignore
      !isUpdating ? handleCreate(a) : handleUpdate(a);
    },
  });

  useEffect(() => {
    if (
      getManagerRequirement?.isSuccess &&
      getManagerRequirement?.data &&
      isUpdating
    )
      setFormikValues(getManagerRequirement?.data, formik);
    // eslint-disable-next-line
  }, [
    getManagerRequirement?.data,
    isUpdating,
    getManagerRequirement?.isSuccess,
  ]);

  async function handleCreate(data: CreateProps) {
    try {
      await api.post("/manager-requirements", data);

      queryClient.invalidateQueries("manager-requirements");

      addToast("Requisito criado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/gerente-requisitos");
    } catch (err) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }
  async function handleUpdate(data: ManagerRequirement) {
    try {
      await api.put(`/manager-requirements/${data.id}`, data);

      queryClient.invalidateQueries(["manager-requirement", data.id]);
      queryClient.invalidateQueries("manager-requirements");

      addToast("Requisito alterado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/gerente-requisitos");
    } catch (err) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <CreateAndDetailLayout isLoading={getManagerRequirement?.isLoading}>
      <PanelAndDetailAndCreateHeader
        title={isUpdating ? "Gerente requisito" : "Criar Gerente requisito"}
        goBack
      />

      <LayoutForm onSubmit={formik.handleSubmit}>
        {isUpdating && (
          <Input
            label="Código"
            {...formik.getFieldProps("id")}
            type="number"
            disabled
          />
        )}

        <GroupInput>
          <Input
            label="Descrição"
            {...formik.getFieldProps("description")}
            error={
              formik.touched.description && formik.errors.description
                ? formik.errors.description
                : undefined
            }
          />
          <Input
            label="Abreviação"
            {...formik.getFieldProps("value")}
            error={
              formik.touched.value && formik.errors.value
                ? formik.errors.value
                : undefined
            }
          />
        </GroupInput>

        <ButtonSubmit>{isUpdating ? "Editar" : "Criar"}</ButtonSubmit>
      </LayoutForm>
    </CreateAndDetailLayout>
  );
};

export default CreateAndDetail;
