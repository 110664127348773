import { useQuery, UseQueryResult } from "react-query";

import { ResponsePanelApiDefault } from "../../@types/panelTypes";
import api from "../../service/api";

export type Manager = {
  id: number;
  name: string;
  is_active: boolean;
};

type GetResponse = {
  contents: Manager[];
  totalCount: number;
};

interface ApiResponse extends ResponsePanelApiDefault {
  contents: Manager[];
}

export async function getManagers(
  page: number,
  registersPerPag?: number
): Promise<GetResponse> {
  const { data } = await api.get<ApiResponse>("/managers", {
    params: { pageRequest: page - 1, limitRequest: registersPerPag },
  });

  return {
    totalCount: data.total,
    contents: data.contents,
  };
}

export function useManagers(
  page: number,
  registersPerPag?: number
): UseQueryResult<GetResponse> {
  return useQuery(
    ["managers", page, registersPerPag],
    () => getManagers(page, registersPerPag),
    {
      staleTime: 1000 * 60 * 10, // 10 Minutos
    }
  );
}

export async function getManager(id?: number): Promise<Manager | undefined> {
  if (!id && id !== 0) return undefined;

  const { data } = await api.get<Manager>(`/managers/${id}`);

  return data;
}

export function useManager(id?: number): UseQueryResult<Manager> | undefined {
  return useQuery(["manager", id], () => getManager(id), {
    staleTime: 1000 * 60 * 10, // 10 Minutos
  });
}
