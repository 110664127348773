import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import * as Yup from "yup";

import { useToasts } from "react-toast-notifications";
import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { ButtonSubmit } from "../../../components/Form/ButtonSubmit";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { LayoutForm } from "../../../components/Form/LayoutForm";
import { Select } from "../../../components/Form/Select";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import {
  ManagerResponse,
  useManagerResponse,
} from "../../../hook/queries/useManagerResponses";
import api from "../../../service/api";
import { queryClient } from "../../../service/queryClient";
import { setFormikValues } from "../../../utils/setFormikValues";

export interface MatchParams {
  id?: string;
}

type CreateProps = Omit<ManagerResponse, "id">;

const CreateAndDetail: React.FC = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const match = useRouteMatch<MatchParams>();
  const isUpdating = match?.params?.id ? true : false;
  const getManagerResponse = useManagerResponse(Number(match.params.id));

  const formik = useFormik({
    initialValues: {
      id: "",
      description: undefined,
      obs: 1,
      valid: 1,
      value: undefined,
    },
    validationSchema: Yup.object({
      description: Yup.string().required("Descrição é obrigatório"),
      value: Yup.string().required("Valor é obrigatório"),
    }),
    onSubmit: (a) => {
      //@ts-ignore
      !isUpdating ? handleCreate(a) : handleUpdate(a);
    },
  });

  useEffect(() => {
    if (getManagerResponse?.isSuccess && getManagerResponse?.data && isUpdating)
      setFormikValues(getManagerResponse?.data, formik);
    // eslint-disable-next-line
  }, [getManagerResponse?.data, isUpdating, getManagerResponse?.isSuccess]);

  async function handleCreate(data: CreateProps) {
    try {
      await api.post("/manager-responses", {
        description: data.description,
        value: data.value,
        obs: Boolean(Number(data.obs)),
        valid: Boolean(Number(data.valid)),
      });

      queryClient.invalidateQueries("manager-responses");

      addToast("Respostas criado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/gerente-respostas");
    } catch (err) {
      const error = err as any;

      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (
        String(error.response.data.message).toLocaleLowerCase() ===
        "value exist"
      ) {
        return addToast("Este valor já existe em nossa base de dados", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }
  async function handleUpdate(data: ManagerResponse) {
    try {
      await api.put(`/manager-responses/${data.id}`, {
        description: data.description,
        value: data.value,
        obs: Boolean(Number(data.obs)),
        valid: Boolean(Number(data.valid)),
      });

      queryClient.invalidateQueries(["manager-response", data.id]);
      queryClient.invalidateQueries("manager-responses");

      addToast("Respostas alterado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/gerente-respostas");
    } catch (err) {
      const error = err as any;

      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (
        String(error.response.data.message).toLocaleLowerCase() ===
        "value exist"
      ) {
        return addToast("Este valor já existe em nossa base de dados", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <CreateAndDetailLayout isLoading={getManagerResponse?.isLoading}>
      <PanelAndDetailAndCreateHeader
        title={
          isUpdating ? "Respostas do gerente" : "Criar Respostas do gerente"
        }
        goBack
      />

      <LayoutForm onSubmit={formik.handleSubmit}>
        {isUpdating && (
          <Input
            label="Código"
            {...formik.getFieldProps("id")}
            type="number"
            disabled
          />
        )}

        <GroupInput>
          <Input
            label="Descrição"
            {...formik.getFieldProps("description")}
            error={
              formik.touched.description && formik.errors.description
                ? formik.errors.description
                : undefined
            }
          />
        </GroupInput>
        <GroupInput>
          <Select
            data={[
              { name: "Sim", value: 0 },
              { name: "Não", value: 1 },
            ]}
            label="Observção"
            {...formik.getFieldProps("obs")}
            error={
              formik.touched.obs && formik.errors.obs
                ? formik.errors.obs
                : undefined
            }
          />
          <Select
            data={[
              { name: "Sim", value: 0 },
              { name: "Não", value: 1 },
            ]}
            label="Valido"
            {...formik.getFieldProps("valid")}
            error={
              formik.touched.valid && formik.errors.valid
                ? formik.errors.valid
                : undefined
            }
          />
        </GroupInput>

        <Input
          label="Valor"
          {...formik.getFieldProps("value")}
          error={
            formik.touched.value && formik.errors.value
              ? formik.errors.value
              : undefined
          }
        />

        <ButtonSubmit>{isUpdating ? "Editar" : "Criar"}</ButtonSubmit>
      </LayoutForm>
    </CreateAndDetailLayout>
  );
};

export default CreateAndDetail;
