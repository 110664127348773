import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import * as Yup from "yup";

import { useToasts } from "react-toast-notifications";
import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { ButtonSubmit } from "../../../components/Form/ButtonSubmit";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { LayoutForm } from "../../../components/Form/LayoutForm";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { Manager, useManager } from "../../../hook/queries/useManagers";
import api from "../../../service/api";
import { queryClient } from "../../../service/queryClient";
import { setFormikValues } from "../../../utils/setFormikValues";

export interface MatchParams {
  id?: string;
}

type ColorCreateProps = Omit<Manager, "id">;

const Colors: React.FC = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const match = useRouteMatch<MatchParams>();
  const isUpdating = match?.params?.id ? true : false;
  const getManager = useManager(Number(match.params.id));

  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("É obrigatório"),
    }),
    onSubmit: (a) => {
      //@ts-ignore
      !isUpdating ? handleCreate(a) : handleUpdate(a);
    },
  });

  useEffect(() => {
    if (getManager?.isSuccess && getManager?.data && isUpdating)
      setFormikValues(getManager?.data, formik);
    // eslint-disable-next-line
  }, [getManager?.data, isUpdating, getManager?.isSuccess]);

  async function handleCreate(data: ColorCreateProps) {
    try {
      await api.post("/managers", data);

      queryClient.invalidateQueries("managers");

      addToast("Gerente criado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/gerentes");
    } catch (err) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }
  async function handleUpdate(data: Manager) {
    try {
      await api.put(`/managers/${data.id}`, data);

      queryClient.invalidateQueries(["manager", data.id]);
      queryClient.invalidateQueries("managers");

      addToast("Gerente alterado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/gerentes");
    } catch (err) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <CreateAndDetailLayout isLoading={getManager?.isLoading}>
      <PanelAndDetailAndCreateHeader
        title={isUpdating ? "Gerente" : "Criar Gerente"}
        goBack
      />

      <LayoutForm onSubmit={formik.handleSubmit}>
        {isUpdating && (
          <Input
            label="Código"
            {...formik.getFieldProps("id")}
            type="number"
            disabled
          />
        )}

        <GroupInput>
          <Input
            label="Nome"
            {...formik.getFieldProps("name")}
            error={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : undefined
            }
          />
        </GroupInput>

        <ButtonSubmit>{isUpdating ? "Editar" : "Criar"}</ButtonSubmit>
      </LayoutForm>
    </CreateAndDetailLayout>
  );
};

export default Colors;
