import React from "react";
import { FiDatabase } from "react-icons/fi";
import { GoGraph } from "react-icons/go";
import { HiOutlineHome } from "react-icons/hi";

import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  margin-top: 25px;
  border-radius: 8px;

  ul {
    width: 100%;
    overflow: hidden;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      flex-direction: column;

      width: 100%;
      padding: 2px 0px;

      cursor: pointer;
      position: relative;
      transition: all 0.3s;

      &:hover {
        ul {
          display: block;
        }

        svg {
          color: ${(props) => props.theme.colors.primary};
        }
      }

      .itemNav {
        display: flex;
        align-items: center;
        flex: 1;
        padding: 10px 10px;

        text-decoration: none;
        color: #333;
      }

      span {
        display: none;
        color: #333;
      }
    }

    .active {
      svg {
        color: ${(props) => props.theme.colors.primary};
      }

      &::after {
        content: "";
        width: 4px;
        height: 100%;
        position: absolute;
        right: 0px;
        background-color: ${(props) => props.theme.colors.primary};
      }
    }
  }
`;

const SubMenu = styled.ul`
  display: none;
  border-left: 2.5px solid ${(props) => props.theme.colors.primary};
  margin-left: 15px;

  li {
    .subItemNav {
      display: flex;
      align-items: center;
      flex: 1;
      padding: 5px 0px;

      text-decoration: none;
      color: #333;
    }

    &:hover {
      background-color: rgba(55, 55, 55, 0.1);
    }
  }
`;

const ItemsNav: React.FC = () => {
  return (
    <Container>
      <ul>
        <li>
          <Link className="itemNav" to={"/inicio"}>
            <HiOutlineHome size={24} />
            <span> Página inicial </span>

            <div className="active-tab" />
          </Link>
        </li>

        <li>
          <Link className="itemNav" to={"/avaliacoes"}>
            <GoGraph size={24} />
            <span> Avaliações </span>
          </Link>
        </li>

        <li>
          <div className="itemNav">
            <FiDatabase size={24} />
            <span> Requisitos </span>
          </div>

          <SubMenu className="subMenu">
            <li>
              <Link className="subItemNav" to="/respostas">
                Respostas
              </Link>
            </li>
            <li>
              <Link className="subItemNav" to="/requisitos">
                Requisitos
              </Link>
            </li>
            <li>
              <Link className="subItemNav" to="/setores">
                Setores
              </Link>
            </li>
            <li>
              <Link className="subItemNav" to="/gerentes">
                Gerentes
              </Link>
            </li>
            <li>
              <Link className="subItemNav" to="/gerente-requisitos">
                Gerente Requisito
              </Link>
            </li>
            <li>
              <Link className="subItemNav" to="/gerente-respostas">
                Gerente Repostas
              </Link>
            </li>
            <li>
              <Link className="subItemNav" to="/beneficios">
                Benefícios
              </Link>
            </li>
          </SubMenu>
        </li>
      </ul>
    </Container>
  );
};

export default ItemsNav;
